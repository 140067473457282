<template>
  <div class="px-2" id="favorite">
    
    <ul class="nav nav-pills mb-4 d-flex" id="pills-tab" role="tablist">
      <li class="nav-item col">
        <a class="nav-link px-0 active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">
          <div class="title font-weight-bold h5 text-center m-0">المفضلة</div>
        </a>
      </li>
      <li class="nav-item col">
        <a class="nav-link px-0" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">
          <div class="title font-weight-bold h5 text-center m-0">قوائم التشغيل</div>
        </a>
      </li>
    </ul>

    <div class="tab-content" id="pills-tabContent">
      <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
        <div class="row justify-content-md-center mx-0">
          
            <HorizontalCardLoader v-if="$apollo.queries.me.loading" />

            <div class="col-md-12 px-0" v-for="track in favoriteTracks" :key="track.id">
              <TrackHorizontalCard :track="track" :tracklist="favoriteTracks" />
            </div>

            <div class="col-md-12 text-center" v-if="!$apollo.queries.me.loading && !favoriteTracks.length">
              <div class="main-card p-5">
                لم تقم باﻹعجاب بأي ملف صوتي!
                <small class="mt-3 card-secondary-lable d-block">
                  عند تشغيل ملف صوتي ما قم بالضغط على إيقونة  <i class="la la-heart-o mx-1 text-danger"></i> ليتم إضافته لقائمة المفضلة لديك.
                </small>
              </div>
            </div>
        </div>
      </div>

      <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
        <div class=" justify-content-md-center mx-0">
          <div v-if="$apollo.queries.me.loading" class="">
            <HorizontalPlaylistCardLoader />
          </div>
          <div v-if="!$apollo.queries.me.loading">
            <div class="mb-2" v-for="playlist in me.playlists" :key="playlist.id">
              <router-link :to="{ name: 'Playlist', params: { id: playlist.id } }">
                <div class="playlist-item d-flex shadow-sm p-2 cursor-pointer card-secondary-lable">
                  <div class="icon me-3"><i class="la la-file-audio la-3x"></i></div>
                  <div class="">
                      <div class="card-title">{{ playlist.name }}</div>
                      <div> 
                        <span v-if="playlist.tracksCount < 1">خالية</span>
                        <span v-else-if="playlist.tracksCount == 1">ملف واحد</span>
                        <span v-else-if="playlist.tracksCount == 2">ملفين</span>
                        <span v-else><span class="font-weight-bold">{{ playlist.tracksCount }}</span> ملفات</span>
                      </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>

          <div class="col-md-12 text-center" v-if="!$apollo.queries.me.loading && !me.playlists.length">
            <div class="main-card p-5">
              لم تقم بإنشاء قوائم تشغيل!
              <small class="mt-3 card-secondary-lable d-block">
                عند تشغيل ملف صوتي ما قم بالضغط على إيقونة  <i class="la la-plus-square mx-1 text-dark la-lg"></i> ﻹضافته ﻷي قائمة تشغيل من إنشائك
              </small>
            </div>
          </div>

        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import gql from "graphql-tag"
import TrackHorizontalCard from '../../components/TrackHorizontalCard.vue';
import HorizontalCardLoader from '../../components/Loader/HorizontalCardLoader.vue';
import HorizontalPlaylistCardLoader from '../../components/Loader/HorizontalPlaylistCardLoader.vue';

export default {
  components: { TrackHorizontalCard, HorizontalCardLoader, HorizontalPlaylistCardLoader },

  data() {
    return {
      me: {},
    }
  },

  computed: {
    favoriteTracks () {
      return this.me.favorites ? this.me.favorites.map(f => f.track): [];
    }
  },

  apollo: {
    me: {
      query: gql`
        query {
          me {
            playlists {
              id
              name

              tracksCount
            }

            favorites {
              track {
                id
                name_ar
                name_en
                description_ar
                description_en
                duration_display
                thumbnail_url
                cover_url
                full_url

                authors {
                  id
                  name_ar
                  name_en
                }

                categories {
                  id
                  name_ar
                  name_en
                }
              }
            }
          }
        }
      `,
      fetchPolicy: 'cache-and-network',
    }
  }
}
</script>

<style>
</style>