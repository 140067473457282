<template>
    <div class="col-md-12 mb-2" v-for="i in 4" :key="i">
        <div class="shadow-sm">
            <ContentLoader width="400" height="70" >
            <rect x="350" y="10" rx="3" ry="3" width="35" height="45" />
            <rect x="190" y="12" rx="3" ry="3" width="140" height="20" />
            <rect x="200" y="45" rx="3" ry="3" width="130" height="10" />
            </ContentLoader>
        </div>
    </div>
</template>

<script>
import { ContentLoader } from 'vue-content-loader'

export default {
    components: {
        ContentLoader,
    }
}
</script>

<style>

</style>